import React, { lazy, Suspense } from 'react';
import { Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';

import history from './history';
import { store } from './index';
import { ClipLoader } from 'react-spinners';

const AccountEdit = lazy(() => import('components/AccountEdit/AccountEdit'));
const AppContainer = lazy(() =>
  import('./components/AppContainer/AppContainer')
);
const PortalContainer = lazy(() =>
  import('./components/PortalContainer/PortalContainer')
);
const PrivateRoute = lazy(() =>
  import('./components/PrivateRoute/PrivateRoute')
);
const RootAdminRoute = lazy(() =>
  import('./components/RootAdminRoute/RootAdminRoute')
);
const UsersContainer = lazy(() =>
  import('./components/UsersContainer/UsersContainer')
);
const ReportsContainer = lazy(() =>
  import('./components/ReportsContainer/ReportContainer')
);
const TourAccountsContainer = lazy(() =>
  import('./components/TourAccountsContainer/TourAccountsContainer')
);
const UsersEdit = lazy(() =>
  import('./components/UsersContainer/UsersEdit/UsersEdit')
);
const TourAccountCreate = lazy(() =>
  import('./components/TourAccountCreate/TourAccountCreate')
);
const ApiUsage = lazy(() =>
  import('./components/ReportsContainer/ApiUsage/ApiUsage')
);
const DefaultPermissions = lazy(() =>
  import('components/DefaultPermissions/DefaultPermissions')
);
const LocalistRouter = lazy(() => import('components/Localist/LocalistRouter'));
const TransitRouter = lazy(() => import('components/Transit/TransitRouter'));
const Unathorized = lazy(() => import('components/Pages/Error/Unathorized'));
const NotFound = lazy(() => import('components/Pages/NotFound/NotFound'));

const Routes = () => {
  const { keycloak } = useKeycloak();
  return (
    <Provider store={store}>
      <Router history={history}>
        <div>
          <Suspense fallback={<ClipLoader />}>
            <PrivateRoute
              auth={keycloak}
              path="/"
              component={(props) => <AppContainer auth={keycloak} {...props} />}
            />
            <RootAdminRoute
              path="/"
              exact
              auth={keycloak}
              store={store}
              component={(props) => <PortalContainer {...props} />}
            />
            <RootAdminRoute
              path="/admin"
              exact
              auth={keycloak}
              store={store}
              component={(props) => <AccountEdit {...props} />}
            />
            <RootAdminRoute
              path="/users"
              exact
              auth={keycloak}
              store={store}
              component={(props) => <UsersContainer {...props} />}
            />
            <RootAdminRoute
              path="/reports"
              exact
              auth={keycloak}
              store={store}
              component={(props) => <ReportsContainer {...props} />}
            />
            <RootAdminRoute
              path="/reports/apiusage"
              exact
              auth={keycloak}
              store={store}
              component={(props) => <ApiUsage />}
            />
            <RootAdminRoute
              path="/accounts/tour"
              exact
              auth={keycloak}
              store={store}
              component={(props) => <TourAccountsContainer {...props} />}
            />
            <RootAdminRoute
              path="/accounts/tour/:aid"
              exact
              auth={keycloak}
              store={store}
              component={(props) => <AccountEdit {...props} />}
            />
            <RootAdminRoute
              path="/accounts/create"
              exact
              auth={keycloak}
              store={store}
              component={(props) => <TourAccountCreate {...props} />}
            />
            <RootAdminRoute
              path="/users/:uid"
              exact
              auth={keycloak}
              store={store}
              component={(props) => <UsersEdit {...props} />}
            />
            <RootAdminRoute
              path="/permissions/tour"
              auth={keycloak}
              store={store}
              component={(props) => <DefaultPermissions {...props} />}
            />
            <PrivateRoute
              path="/localist-events"
              auth={keycloak}
              component={(props) => (
                <LocalistRouter auth={keycloak} {...props} />
              )}
            />
            <PrivateRoute
              path="/transit"
              auth={keycloak}
              component={(props) => (
                <TransitRouter auth={keycloak} {...props} />
              )}
            />
            <Route exact path="/401" render={({ match }) => <Unathorized />} />
            <Route exact path="/404" render={({ match }) => <NotFound />} />
          </Suspense>
        </div>
      </Router>
    </Provider>
  );
};

export default Routes;

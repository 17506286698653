import { MapActionTypes } from '../actions/mapActions';

export const initialState = {
  mapCategories: [],
  mapLocations: [],
  status: 'ok',
  mapCatStatus: 'ok',
  mapLocStatus: 'ok',
};

function maps(state = initialState, action) {
  switch (action.type) {
    case MapActionTypes.SET_MAP_CATEGORIES: {
      return {
        ...state,
        mapCategories: action.data,
      };
    }
    case MapActionTypes.SET_MAP_LOCATIONS: {
      return {
        ...state,
        mapLocations: action.data,
      };
    }
    case MapActionTypes.RESET_MAP_DATA: {
      return {
        ...state,
        mapLocations: [],
        mapCategories: [],
      };
    }
    case MapActionTypes.SET_MAP_CATEGORY_STATUS: {
      return {
        ...state,
        mapCatStatus: action.data,
      };
    }
    case MapActionTypes.SET_MAP_LOCATIONS_STATUS: {
      return {
        ...state,
        mapLocStatus: action.data,
      };
    }
    case MapActionTypes.SET_MAPS_STATUS: {
      return {
        ...state,
        status: action.data,
      };
    }
    default:
      return state;
  }
}

export default maps;

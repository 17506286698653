import React from 'react';
import Modal from 'react-modal';
import Button from '../../Buttons/Button';
import cx from 'classnames';
import './DialogModal.scss';

const DialogModal = (props) => {
  const {
    children,
    styles,
    className,
    closeModal,
    isOpen,
    shouldCloseOnOverlayClick,
    ariaAttributes,
    id,
    hideBackground,
  } = props;
  const hasAriaAttributes =
    typeof ariaAttributes === 'object' &&
    Object.keys(ariaAttributes).length > 0;
  const hasId = id || '';

  const modalClasses = cx('dialog-modal', {
    [className]: className,
  });
  const overlayClasses = cx('dialog-modal-overlay', {
    [className]: className,
    'no-background': hideBackground,
  });
  return (
    <Modal
      isOpen={isOpen}
      style={styles}
      className={modalClasses}
      overlayClassName={overlayClasses}
      id={hasId}
      onRequestClose={closeModal}
      closeTimeoutMS={500}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      aria={hasAriaAttributes ? ariaAttributes : {}}
      appElement={document.querySelector('#root')}
    >
      {shouldCloseOnOverlayClick && (
        <Button
          handleClick={closeModal}
          className="dialog-modal-close-btn"
          aria-label="close modal"
          data-testid="dialog-modal-close-btn"
          data-cy="dialog-modal-close-btn"
        >
          ×
        </Button>
      )}
      {children}
    </Modal>
  );
};

export default DialogModal;

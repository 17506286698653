import { TransitActionTypes } from '../actions/transitActions';

export const initialState = {
  transit_status: 'ok',
  transit_route_status: 'ok',
  transit_config: {},
  transit_routes: [],
  map_transits: [],
};

function transit(state = initialState, action) {
  switch (action.type) {
    case TransitActionTypes.SET_TRANSIT_CONFIG: {
      return {
        ...state,
        transit_config: action.data,
      };
    }
    case TransitActionTypes.SET_TRANSIT_ROUTES: {
      return {
        ...state,
        transit_routes: action.data,
      };
    }
    case TransitActionTypes.SET_TRANSIT_STATUS: {
      return {
        ...state,
        transit_status: action.data,
      };
    }
    case TransitActionTypes.SET_TRANSIT_ROUTE_STATUS: {
      return {
        ...state,
        transit_route_status: action.data,
      };
    }
    case TransitActionTypes.SET_MAP_TRANSITS: {
      return {
        ...state,
        map_transits: action.data,
      };
    }
    case TransitActionTypes.REMOVE_MAP_TRANSITS: {
      const ind = state.map_transits.indexOf(
        (config) => (config.data.transit_id = action.data.transitId)
      );
      state.map_transits.splice(ind, 1);
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}

export default transit;

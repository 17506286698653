export const initialState = {
  userProfile: {},
  usersObj: {},
  userNotFound: false,
  userAccount: {},
  userAccounts: [],
  userCount: 0,
};

function users(state = initialState, action) {
  switch (action.type) {
    case 'SAVE_ALL_USERS': {
      const usersObj = action.data.reduce((acc, item) => {
        acc[item.uid] = item;
        return acc;
      }, {});
      return Object.assign({}, state, {
        usersObj,
      });
    }
    case 'SAVE_USER': {
      return Object.assign({}, state, {
        usersObj: Object.assign({}, state.usersObj, {
          [action.data.uid]: action.data,
        }),
      });
    }
    case 'SAVE_USER_PROFILE': {
      const { firstname, lastname, email, img } = action.data;
      return Object.assign({}, state, {
        userProfile: {
          firstname,
          lastname,
          email,
          img,
        },
      });
    }
    case 'SAVE_USER_ACCOUNT': {
      return Object.assign({}, state, {
        userAccount: action.data,
      });
    }
    case 'USER_NOT_FOUND': {
      return Object.assign({}, state, {
        userNotFound: true,
      });
    }
    case 'SET_USERS':
      return Object.assign({}, state, {
        userAccounts: action.data,
      });
    case 'SET_USER_COUNT':
      return Object.assign({}, state, {
        userCount: action.data,
      });
    case 'SORT_USER_ACCOUNTS':
      state.userAccounts.sort((a, b) => {
        const isNum = !isNaN(a[action.data.attribute]);
        if (!action.data.ascending)
          return a[action.data.attribute]
            .toString()
            .localeCompare(b[action.data.attribute], undefined, {
              numeric: isNum,
            });
        return b[action.data.attribute]
          .toString()
          .localeCompare(a[action.data.attribute], undefined, {
            numeric: isNum,
          });
      });
      return {
        ...state,
      };
    default:
      return state;
  }
}

export default users;

import React, { Component } from 'react';
import './Button.scss';

class Button extends Component {
  render() {
    const { children, className, style, onClick, name, disabled } = this.props;
    return (
      <button
        className={className}
        name={name}
        style={style}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }
}

export default Button;

export const initialState = {
  tourAccounts: {},
  individualTourAccount: {},
};

function tours(state = initialState, action) {
  switch (action.type) {
    case 'SAVE_ALL_TOUR_ACCOUNTS': {
      const tourAccounts = action.data.reduce((acc, item) => {
        acc[item.aid] = item;
        return acc;
      }, {});
      return Object.assign({}, state, {
        tourAccounts,
      });
    }
    case 'SAVE_TOUR_ACCOUNT': {
      return Object.assign({}, state, {
        tourAccounts: Object.assign({}, state.tourAccounts, {
          [action.data.aid]: action.data,
        }),
      });
    }
    case 'SET_SAVE_CONFIRMED': {
      return Object.assign({}, state, {
        accountSaved: action.data,
      });
    }
    case 'SET_INDIVIDUAL_TOUR_ACCOUNT': {
      return Object.assign({}, state, {
        individualTourAccount: action.data,
      });
    }
    case 'SET_INDIVIDUAL_TOUR_DATA': {
      return Object.assign({}, state, {
        individualTourData: action.data,
      });
    }
    default:
      return state;
  }
}

export default tours;

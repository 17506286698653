import { _getMapCategories, _getMapLocations } from 'api/maps';

import { ACTION_STATUS } from './../constants';
import { batch } from 'react-redux';
import { handleFeedActionError } from './feedActions';

export const MapActionTypes = {
  SET_MAP_CATEGORIES: 'MAPS::SET_MAP_CATEGORIES',
  SET_MAP_LOCATIONS: 'MAPS::SET_MAP_LOCATIONS',
  SET_MAPS_STATUS: 'MAPS::SET_MAPS_STATUS',
  RESET_MAP_DATA: 'MAPS::RESET_MAP_DATA',
  SET_MAP_CATEGORY_STATUS: 'MAPS::SET_MAP_CATEGORY_STATUS',
  SET_MAP_LOCATIONS_STATUS: 'MAPS::SET_MAP_LOCATIONS_STATUS',
};

export const getMapCategories = ({ mapId, search, size = 100, page = 1 }) => {
  return (dispatch) => {
    dispatch(setMapCategoryStatus(ACTION_STATUS.LOADING));
    return _getMapCategories({ mapId, search, size, page })
      .then((categories) => {
        batch(() => {
          dispatch(setMapCategories(categories.rows));
          dispatch(setMapCategoryStatus(ACTION_STATUS.OK));
        });
      })
      .catch((err) => {
        handleFeedActionError(err, dispatch);
      });
  };
};

export const getMapLocations = ({ mapId, search, size = 100, page = 1 }) => {
  return (dispatch) => {
    dispatch(setMapLocationsStatus(ACTION_STATUS.LOADING));
    return _getMapLocations({ mapId, search, size, page })
      .then((locations) => {
        batch(() => {
          dispatch(setMapLocations(locations.rows));
          dispatch(setMapLocationsStatus(ACTION_STATUS.OK));
        });
      })
      .catch((err) => {
        handleFeedActionError(err, dispatch);
      });
  };
};

export const setMapCategories = (data) => {
  return {
    type: MapActionTypes.SET_MAP_CATEGORIES,
    data,
  };
};

export const setMapLocations = (data) => {
  return {
    type: MapActionTypes.SET_MAP_LOCATIONS,
    data,
  };
};

export const resetMapData = () => {
  return {
    type: MapActionTypes.RESET_MAP_DATA,
  };
};

export const setMapCategoryStatus = (data) => {
  return {
    type: MapActionTypes.SET_MAP_CATEGORY_STATUS,
    data,
  };
};

export const setMapLocationsStatus = (data) => {
  return {
    type: MapActionTypes.SET_MAP_LOCATIONS_STATUS,
    data,
  };
};

export const setMapsStatus = (data) => {
  return {
    type: MapActionTypes.SET_MAPS_STATUS,
    data,
  };
};

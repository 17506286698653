import './index.scss';
import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import Routes from './routes';
import rootReducer from './reducers/rootReducer.js';
import registerServiceWorker from './registerServiceWorker';
import WebFontLoader from 'webfontloader';
import { keycloak } from './keycloak';
import CacheBuster from './components/CacheBuster/CacheBuster.js';
import 'react-datepicker/dist/react-datepicker.css';
import { AppSizeListener } from 'react-md';

WebFontLoader.load({
  google: {
    families: ['Roboto:300,400,500,700', 'Material Icons'],
  },
});

export const history = createBrowserHistory();

const middleware = [thunk];

const composedEnhancer = compose(
  applyMiddleware(...middleware),
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f
);

export const store = createStore(rootReducer, composedEnhancer);

ReactDOM.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{ checkLoginIframe: false, onLoad: 'login-required' }}
  >
    <AppSizeListener>
      <Routes />
      <CacheBuster />
    </AppSizeListener>
  </ReactKeycloakProvider>,
  document.getElementById('root')
);
registerServiceWorker();

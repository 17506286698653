export const ACTION_STATUS = {
  LOADING: 'loading',
  OK: 'ok',
  UNAUTHORIZED: 'unauthorized',
  SAVING: 'saving',
  ERROR: 'error',
  NOTFOUND: 'not_found',
  PENDING: 'pending',
  COMPLETE: 'complete',
};

// in seconds
export const DOWNLOADTIMEOUT = 100; // multiplied by 3
export const LAMBDATIMEOUT = 300; // multiplied by 3 - lambdas may run for up to 15 min
export const DEFAULTGTFSVALUES = {
  transitType: 'GTFS',
  enabled: false,
  frontend: false,
  frequency: 24,
  cms: false,
  url: '',
  rtTripFeedUrl: '',
  rtVehiclePositionFeedUrl: '',
  rtServiceFeedUrl: '',
  rtServiceFeedEnabled: false,
};

import credentials from '../credentials.js';
import { handleResponse } from './helpers/helpers';
import { buildAuthHeaders } from '../utilities/buildAuthHeaders';
import { LAMBDATIMEOUT } from 'constants';

export const _getMapFeeds = async ({
  type = 'localist',
  search = '',
  page,
  size,
}) => {
  const find = search ? `&search=${search}` : '';
  const pg = page ? `&page=${page}` : '';
  const sz = size ? `&size=${size}` : '';
  const url = `${credentials.accountsAPI}/feeds?type=${type}${find}${pg}${sz}`;

  const res = await fetch(url, {
    method: 'GET',
    headers: buildAuthHeaders(),
  });

  return await handleResponse(res);
};

export const _createMapFeed = async (accountFeed) => {
  const res = await fetch(`${credentials.accountsAPI}/feeds`, {
    method: 'POST',
    body: JSON.stringify(accountFeed),
    headers: buildAuthHeaders(),
  });
  return await handleResponse(res);
};

export const _updateMapFeed = async (accountFeed) => {
  const { feedId } = accountFeed;
  const res = await fetch(`${credentials.accountsAPI}/feeds/${feedId}`, {
    method: 'PUT',
    body: JSON.stringify(accountFeed),
    headers: buildAuthHeaders(),
  });
  return await handleResponse(res);
};

export const _getCategoryFeeds = async ({ mapId, search, size, page }) => {
  try {
    const find = search ? `&search=${search}` : '';
    const pg = page ? `&page=${page}` : '';
    const sz = size ? `&size=${size}` : '';
    const url = `${credentials.accountsAPI}/feeds/${mapId}/categories?type=localist${find}${pg}${sz}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: buildAuthHeaders(),
    });
    return await handleResponse(res);
  } catch (error) {
    throw error;
  }
};

export const _getCategoryFeed = async ({ mapId, catId }) => {
  try {
    const url = `${credentials.accountsAPI}/feeds/${mapId}/categories/${catId}`;
    const res = await fetch(url, {
      method: 'GET',
      headers: buildAuthHeaders(),
    });
    return await handleResponse(res);
  } catch (error) {
    throw error;
  }
};

export const _createCategoryFeed = async ({ mapId, catId, categoryFeed }) => {
  try {
    const url = `${credentials.accountsAPI}/feeds/${mapId}/categories/${catId}`;
    const res = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(categoryFeed),
      headers: buildAuthHeaders(),
    });
    return await handleResponse(res);
  } catch (error) {
    throw error;
  }
};

export const _updateCategoryFeed = async ({ mapId, catId, categoryFeed }) => {
  try {
    const url = `${credentials.accountsAPI}/feeds/${mapId}/categories/${catId}`;
    const res = await fetch(url, {
      method: 'PUT',
      body: JSON.stringify(categoryFeed),
      headers: buildAuthHeaders(),
    });
    return await handleResponse(res);
  } catch (error) {
    throw error;
  }
};

export const _getLocalistContent = async ({ url, eventResource, max }) => {
  try {
    let current = 1;
    let pageCount = 1;
    const allItems = [];
    const maxRes = max || Number.MAX_VALUE;

    while (current <= pageCount && allItems.length < maxRes) {
      const pagedUrl = `${url}&page=${current}`;
      const res = await fetch(pagedUrl, {
        method: 'GET',
      });
      const results = await handleResponse(res);
      if (results?.page) {
        const { total } = results?.page;
        current++;
        pageCount = total;
      } else {
        current = Number.MAX_VALUE;
      }

      const items = results[eventResource] || [];
      allItems.push(...items);
    }

    if (max && allItems.length > max) allItems.length = maxRes;
    return allItems;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const _deleteLocalistFeed = async ({ mapId, catId }) => {
  try {
    const url = `${credentials.accountsAPI}/feeds/${mapId}/categories/${catId}`;
    const res = await fetch(url, {
      method: 'DELETE',
      headers: buildAuthHeaders(),
    });
    return await handleResponse(res);
  } catch (error) {
    throw error;
  }
};

// Running Localist Feed Methods
export const _executeLocalistFeed = async ({ mapId, catId }) => {
  try {
    const res = await postFeedSQS({ mapId, catId });

    return await feedSAMJobCheck({ mapId, catId });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const postFeedSQS = async ({ mapId, catId }) => {
  const myHeaders = buildAuthHeaders();
  const res = await fetch(
    `${credentials.accountsAPI}/feeds/${mapId}/categories/${catId}/run`,
    {
      headers: myHeaders,
      method: 'POST',
    }
  );

  return await handleResponse(res);
};

const feedSAMJobCheck = async ({ mapId, catId }) => {
  let timeOut = LAMBDATIMEOUT;

  return new Promise((resolve, reject) => {
    const downloadTimer = setInterval(async () => {
      if (timeOut <= 0) {
        clearInterval(downloadTimer);
        reject({
          status: 408,
          message: 'Request Timeout, process took too long',
        });
      }

      const data = await _getCategoryFeed({ mapId, catId });
      if (data.status === 'error' || data.status === 'complete') {
        clearInterval(downloadTimer);
        if (data.status === 'complete') resolve(data);
        else reject(data);
        return;
      }
      --timeOut;
    }, 3000);
  });
};

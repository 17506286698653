export const initialState = {
  tiers: ['trial', 'enterprise'],
  saving: { trial: false, enterprise: false },
};

function tourPermissions(state = initialState, action) {
  switch (action.type) {
    case 'SAVE_ALL_TOUR_PERMISSIONS': {
      const tiersList = [];
      const tiersObj = action.data.reduce((obj, tier) => {
        obj[tier.tier] = tier.config;
        tiersList.push(tier.tier);
        return obj;
      }, {});
      return Object.assign({}, state, {
        tiersObj: tiersObj,
        tiersList,
      });
    }
    case 'UPDATE_TOUR_PERMISSIONS': {
      const keys = Object.keys(action.data);
      const updateObj = keys.reduce((acc, key) => {
        acc[key] = {
          ...state.tiersObj[action.tier][key],
          defaultValue: action.data[key],
        };
        return acc;
      }, {});
      return {
        ...state,
        tiersObj: {
          ...state.tiersObj,
          [action.tier]: {
            ...state.tiersObj[action.tier],
            ...updateObj,
          },
        },
      };
    }
    case 'SET_SAVING_PERMISSIONS': {
      return {
        ...state,
        saving: {
          ...state.saving,
          [action.tier]: action.saving,
        },
      };
    }
    default:
      return state;
  }
}

export default tourPermissions;

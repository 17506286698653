import credentials from '../credentials.js';
import { handleResponse } from './helpers/helpers';
import { buildAuthHeaders } from '../utilities/buildAuthHeaders';

// TODO
// Ideally this should be pointed at the transit api,
// But further work is needed to address this.
export const _getTransitsByMap = async ({ mapId }) => {
  const url = `${credentials.accountsAPI}/transit?mapId=${mapId}`;

  const res = await fetch(url, {
    method: 'GET',
    headers: buildAuthHeaders(),
  });

  return await handleResponse(res);
};

export const _createTransitConfigAtlas = async ({ data }) => {
  const url = `${credentials.accountsAPI}/transit`;

  const res = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: buildAuthHeaders(),
  });
  return await handleResponse(res);
};

export const _createTransitConfig = async ({ data, trigger }) => {
  const url = `${credentials.transitAPI}/transit${
    trigger ? '?trigger=true' : ''
  }`;

  const res = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: buildAuthHeaders(),
  });
  return await handleResponse(res);
};

export const _getTransitConfig = async ({ transitId }) => {
  const url = `${credentials.transitAPI}/transit/${encodeURIComponent(
    transitId
  )}?decode_api_secret=true`;

  const res = await fetch(url, {
    method: 'GET',
    headers: buildAuthHeaders(),
  });

  return await handleResponse(res);
};

export const _putTransitConfig = async ({ transitId, data }) => {
  const url = `${credentials.transitAPI}/transit/${encodeURIComponent(
    transitId
  )}`;

  const res = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: buildAuthHeaders(),
  });

  return await handleResponse(res);
};

export const _putTransitConfigAtlas = async ({ transitId, data }) => {
  const url = `${credentials.accountsAPI}/transit/${encodeURIComponent(
    transitId
  )}`;

  const res = await fetch(url, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: buildAuthHeaders(),
  });

  return await handleResponse(res);
};

export const _deleteTransitConfig = async ({ transitId, mapId }) => {
  const url = `${credentials.transitAPI}/transit/${encodeURIComponent(
    transitId
  )}?mapId=${mapId}`;

  const res = await fetch(url, {
    method: 'DELETE',
    headers: buildAuthHeaders(),
  });

  return await handleResponse(res);
};

export const _deleteTransitConfigAtlas = async ({ transitId, mapId }) => {
  const url = `${credentials.accountsAPI}/transit/${encodeURIComponent(
    transitId
  )}?mapId=${mapId}`;

  const res = await fetch(url, {
    method: 'DELETE',
    headers: buildAuthHeaders(),
  });

  return await handleResponse(res);
};

export const _staticDataETLCheck = async ({ transitId }) => {
  let timeOut = 1000;

  return new Promise((resolve, reject) => {
    const downloadTimer = setInterval(async () => {
      if (timeOut <= 0) {
        clearInterval(downloadTimer);
        reject({
          status: 408,
          message: 'Request Timeout, process took too long',
        });
      }

      const data = await _getTransitConfig({ transitId });
      const [item = {}] = data.rows;
      if (!item.isProcessing) {
        clearInterval(downloadTimer);
        resolve(item);
        return;
      }
      --timeOut;
    }, 3000);
  });
};

export const _getTransitRoutes = async ({
  transitId,
  transitType = 'gtfs',
}) => {
  const url = `${credentials.transitAPI}/transit/${encodeURIComponent(
    transitId
  )}/routes?type=${transitType}`;
  const res = await fetch(url, {
    method: 'GET',
    headers: buildAuthHeaders(),
  });

  return await handleResponse(res);
};

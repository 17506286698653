import { FeedActionTypes } from '../actions/feedActions';

export const initialState = {
  mapFeeds: {},
  mapFeedsCount: 0,
  mapFeedConfig: {},
  categoryFeeds: {},
  categoryFeedsCount: 0,
  feed_status: 'ok',
};

function feeds(state = initialState, action) {
  switch (action.type) {
    case FeedActionTypes.SET_MAP_FEEDS: {
      return Object.assign({}, state, {
        mapFeeds: action.data,
      });
    }
    case FeedActionTypes.SET_MAP_FEEDS_COUNT: {
      return Object.assign({}, state, {
        mapFeedsCount: action.data,
      });
    }
    case FeedActionTypes.UPDATE_MAP_FEEDS: {
      const copy = { ...state.feeds };
      copy[action.data.mapId] = action.data;
      return Object.assign({}, state, {
        mapFeeds: copy,
      });
    }
    case FeedActionTypes.SET_CATEGORY_FEEDS: {
      return Object.assign({}, state, {
        categoryFeeds: action.data,
      });
    }
    case FeedActionTypes.SET_CATEGORY_FEEDS_COUNT: {
      return Object.assign({}, state, {
        categoryFeedsCount: action.data,
      });
    }
    case FeedActionTypes.UPDATE_CATEGORY_FEED: {
      return Object.assign({}, state, {
        categoryFeeds: {
          ...state.categoryFeeds,
          [action.data.catId]: {
            ...state.categoryFeeds[action.data.catId],
            ...action.data,
          },
        },
      });
    }
    case FeedActionTypes.SET_CATEGORY_FEEDS_STATUS: {
      return Object.assign({}, state, {
        categoryFeeds: {
          ...state.categoryFeeds,
          [action.data.catId]: {
            ...state.categoryFeeds[action.data.catId],
            status: action.data.status,
          },
        },
      });
    }
    case FeedActionTypes.SET_MAP_FEED_CONFIG: {
      return Object.assign({}, state, {
        mapFeedConfig: action.data,
      });
    }
    case FeedActionTypes.UPDATE_MAP_FEED_CONFIG: {
      const copy = { ...state.mapFeedConfig };
      copy[action.data.mapId] = action.data;
      return Object.assign({}, state, {
        mapFeedConfig: action.data,
      });
    }
    case FeedActionTypes.SET_FEED_STATUS: {
      return Object.assign({}, state, {
        feed_status: action.data,
      });
    }
    case FeedActionTypes.REMOVE_CATEGORY_FEED: {
      delete state.categoryFeeds[action.data];
      return {
        ...state,
      };
    }
    default:
      return state;
  }
}

export default feeds;

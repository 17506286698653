import {
  _getTransitsByMap,
  _getTransitConfig,
  _putTransitConfig,
  _putTransitConfigAtlas,
  _createTransitConfig,
  _createTransitConfigAtlas,
  _deleteTransitConfig,
  _deleteTransitConfigAtlas,
  _staticDataETLCheck,
  _getTransitRoutes,
} from 'api/transit';

import { ACTION_STATUS } from './../constants';
import { batch } from 'react-redux';
import {
  prepDataForTransit,
  prepDataForAccounts,
} from './../utilities/transitUtils';

export const TransitActionTypes = {
  SET_MAP_TRANSITS: 'TRANSIT::SET_MAP_TRANSITS',
  REMOVE_MAP_TRANSITS: 'TRANSIT::REMOVE_MAP_TRANSITS',
  SET_TRANSIT_CONFIG: 'TRANSIT::SET_TRANSIT_CONFIG',
  SET_TRANSIT_ROUTES: 'TRANSIT::SET_TRANSIT_ROUTES',
  SET_TRANSIT_STATUS: 'TRANSIT::SET_TRANSIT_STATUS',
  SET_TRANSIT_ROUTE_STATUS: 'TRANSIT::SET_TRANSIT_ROUTE_STATUS',
};

export const getTransitConfig = ({ transitId }) => {
  return (dispatch) => {
    dispatch(setTransitStatus(ACTION_STATUS.LOADING));
    return _getTransitConfig({ transitId })
      .then((res) => {
        const [item = {}] = res.rows;
        const prepData = prepDataForAccounts(item);
        batch(() => {
          dispatch(setTransitConfig(prepData));
          dispatch(setTransitStatus(ACTION_STATUS.OK));
        });
      })
      .catch((err) => {
        handleTransitActionError(err, dispatch);
      });
  };
};

export const saveTransitConfig = ({ transitId, data = {}, callback }) => {
  return (dispatch) => {
    const prepData = prepDataForTransit(data);
    dispatch(setTransitStatus(ACTION_STATUS.SAVING));
    return saveCreateTransitConfig({ transitId, data: prepData })
      .then((res) => {
        const { transitId } = res[0];
        const resdata = {
          ...data,
          transitId: transitId,
        };
        batch(() => {
          dispatch(setTransitConfig(resdata));
          dispatch(setTransitStatus(ACTION_STATUS.OK));
        });

        typeof callback === 'function' && callback(transitId);
      })
      .catch((err) => {
        handleTransitActionError(err, dispatch);
      });
  };
};

export const getMapTransits = ({ mapId }) => {
  return (dispatch) => {
    dispatch(setTransitStatus(ACTION_STATUS.LOADING));
    return _getTransitsByMap({ mapId })
      .then((res) => {
        batch(() => {
          dispatch(setMapTransits(res));
          dispatch(setTransitStatus(ACTION_STATUS.OK));
        });
      })
      .catch((err) => {
        handleTransitActionError(err, dispatch);
      });
  };
};

export const deleteTransitConfig = ({ transitId, mapId }) => {
  return (dispatch) => {
    dispatch(setTransitStatus(ACTION_STATUS.LOADING));
    return Promise.all([
      _deleteTransitConfig({ transitId, mapId }),
      _deleteTransitConfigAtlas({ transitId, mapId }),
    ])
      .then((res) => {
        batch(() => {
          dispatch(removeMapTransit({ transitId }));
          dispatch(setTransitStatus(ACTION_STATUS.OK));
        });
      })
      .catch((err) => {
        handleTransitActionError(err, dispatch);
      });
  };
};

export const checkStaticETLData = (transitId) => {
  return (dispatch) => {
    dispatch(setTransitRouteStatus(ACTION_STATUS.LOADING));
    return _staticDataETLCheck({ transitId })
      .then((res) => {
        const prepData = prepDataForAccounts(res);
        batch(() => {
          dispatch(setTransitConfig(prepData));
          dispatch(setTransitRouteStatus(ACTION_STATUS.OK));
        });
      })
      .catch((err) => {
        handleTransitActionError(err, dispatch);
      });
  };
};

export const getTransitRoutes = (transitId, transitType) => {
  return (dispatch) => {
    dispatch(setTransitRouteStatus(ACTION_STATUS.LOADING));
    return _getTransitRoutes({ transitId, transitType })
      .then((res) => {
        const { rows = [] } = res;
        const sorted = rows.sort((a, b) => {
          const enabled = b.enabled - a.enabled;
          if (enabled !== 0) return enabled;

          return a.routeId - b.routeId;
        });
        batch(() => {
          dispatch(setTransitRoutes(sorted));
          dispatch(setTransitRouteStatus(ACTION_STATUS.OK));
        });
      })
      .catch((err) => {
        handleTransitActionError(err, dispatch);
      });
  };
};

export const handleTransitActionError = (err, dispatch) => {
  console.error(err);
  if (err.status === 401) {
    dispatch(setTransitStatus(ACTION_STATUS.UNAUTHORIZED));
  } else if (err.status === 404) {
    dispatch(setTransitStatus(ACTION_STATUS.NOTFOUND));
  } else {
    dispatch(setTransitStatus(ACTION_STATUS.ERROR));
  }
};

const saveCreateTransitConfig = ({ transitId, data }) => {
  if (transitId === 'create') {
    const trigger = data.transit_type === 'gtfs';
    return createTransitConfig({ data, trigger });
  } else {
    return putTransitConfig({ transitId, data });
  }
};

const createTransitConfig = ({ data, trigger }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { transit_id: transitId } = await _createTransitConfig({
        data,
        trigger,
      });
      data.transitId = transitId;
      await _createTransitConfigAtlas({ data });

      resolve([{ transitId }]);
    } catch (err) {
      reject(err);
    }
  });
};

const putTransitConfig = ({ transitId, data }) => {
  return Promise.all([
    _putTransitConfigAtlas({ transitId, data }),
    _putTransitConfig({ transitId, data }),
  ]);
};

export const setMapTransits = (data) => {
  return {
    type: TransitActionTypes.SET_MAP_TRANSITS,
    data,
  };
};

export const removeMapTransit = (data) => {
  return {
    type: TransitActionTypes.REMOVE_MAP_TRANSITS,
    data,
  };
};

export const setTransitConfig = (data) => {
  return {
    type: TransitActionTypes.SET_TRANSIT_CONFIG,
    data,
  };
};

export const setTransitRoutes = (data) => {
  return {
    type: TransitActionTypes.SET_TRANSIT_ROUTES,
    data,
  };
};

export const setTransitStatus = (data) => {
  return {
    type: TransitActionTypes.SET_TRANSIT_STATUS,
    data,
  };
};

export const setTransitRouteStatus = (data) => {
  return {
    type: TransitActionTypes.SET_TRANSIT_ROUTE_STATUS,
    data,
  };
};

export const resetTransitConfig = () => {
  return (dispatch) => {
    dispatch(setTransitConfig({}));
  };
};

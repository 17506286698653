import { combineReducers } from 'redux';
import tourPermissions from './tourPermissions';
import users from './users';
import tours from './tours';
import feeds from './feeds';
import maps from './maps';
import transit from './transit';

const rootReducer = combineReducers({
  users,
  tours,
  tourPermissions,
  feeds,
  maps,
  transit,
});

export default rootReducer;

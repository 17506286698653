import credentials from '../credentials.js';
import { handleResponse } from './helpers/helpers';
import { buildAuthHeaders } from '../utilities/buildAuthHeaders';

export const _getMapCategories = async ({ mapId, size, page, search = '' }) => {
  const find = search ? `search=${search}&` : '';
  const pg = page ? `page=${page}&` : '';
  const sz = size ? `size=${size}&` : '';
  const url = `${credentials.accountsAPI}/maps/${mapId}/categories?${find}${pg}${sz}`;

  const res = await fetch(url, {
    method: 'GET',
    headers: buildAuthHeaders(),
  });

  return await handleResponse(res);
};

export const _getMapLocations = async ({ mapId, search = '', page, size }) => {
  const find = search ? `search=${search}&` : '';
  const pg = page ? `page=${page}&` : '';
  const sz = size ? `size=${size}&` : '';
  const url = `${credentials.accountsAPI}/maps/${mapId}/locations?${find}${pg}${sz}`;

  const res = await fetch(url, {
    method: 'GET',
    headers: buildAuthHeaders(),
  });

  return await handleResponse(res);
};

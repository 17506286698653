export const handleResponse = async (res, noAuth) => {
  const type = res.headers.get('Content-Type');
  const result =
    type && type.includes('application/json')
      ? await res.json()
      : await res.text();
  if (!res.ok) throw result;
  return result;
};

export const handleResponseType = async (res) => {
  const contentType = res.headers.get('Content-Type');

  if (contentType.includes('application/json')) {
    return res.json();
  } else if (
    contentType.includes('image') ||
    contentType.includes('video') ||
    contentType.includes('audio') ||
    contentType.includes('csv')
  ) {
    return res.blob();
  } else {
    return res.text();
  }
};

import {
  _getMapFeeds,
  _createMapFeed,
  _updateMapFeed,
  _getCategoryFeeds,
  _createCategoryFeed,
  _updateCategoryFeed,
  _executeLocalistFeed,
  _deleteLocalistFeed,
} from 'api/feeds';

import { ACTION_STATUS } from './../constants';
import { batch } from 'react-redux';

export const FeedActionTypes = {
  SET_MAP_FEEDS: 'FEEDS::SET_MAP_FEEDS',
  SET_MAP_FEEDS_COUNT: 'FEEDS::SET_MAP_FEEDS_COUNT',
  SET_CATEGORY_FEEDS: 'FEEDS::SET_CATEGORY_FEEDS',
  SET_CATEGORY_FEEDS_COUNT: 'FEEDS::SET_CATEGORY_FEEDS_COUNT',
  UPDATE_CATEGORY_FEED: 'FEEDS::UPDATE_CATEGORY_FEED',
  SET_CATEGORY_FEEDS_STATUS: 'FEEDS::SET_CATEGORY_FEEDS_STATUS',
  SET_MAP_FEED_CONFIG: 'FEEDS::SET_MAP_FEED_CONFIG',
  UPDATE_MAP_FEED_CONFIG: 'FEEDS::UPDATE_MAP_FEED_CONFIG',
  SET_FEED_STATUS: 'FEEDS::SET_FEED_STATUS',
  REMOVE_CATEGORY_FEED: 'FEEDS::REMOVE_CATEGORY_FEED',
  UPDATE_MAP_FEEDS: 'FEEDS::UPDATE_MAP_FEEDS',
};

export const getMapFeeds = ({ type, search, size = 10, page = 1 }) => {
  return (dispatch) => {
    dispatch(setFeedStatus(ACTION_STATUS.LOADING));
    return _getMapFeeds({ type, search, size, page })
      .then((res) => {
        const { rows, count } = res;
        const feeds = {};
        rows.forEach((feed) => (feeds[feed.MAPID] = feed));

        batch(() => {
          dispatch(setMapFeeds(feeds));
          dispatch(setMapFeedsCount(count));
          dispatch(setFeedStatus(ACTION_STATUS.OK));
        });
      })
      .catch((err) => {
        handleFeedActionError(err, dispatch);
      });
  };
};

export const saveMapFeed = ({ accountFeed, method }) => {
  return (dispatch) => {
    dispatch(setFeedStatus(ACTION_STATUS.SAVING));
    return saveUpdateMapFeed({ accountFeed, method })
      .then(({ feedId }) => {
        if (feedId) accountFeed.feedId = feedId;

        batch(() => {
          dispatch(setMapFeedConfig(accountFeed));
          dispatch(setFeedStatus(ACTION_STATUS.OK));
        });
      })
      .catch((err) => {
        handleFeedActionError(err, dispatch);
      });
  };
};

export const getCategoryFeeds = ({ mapId, search, size = 5, page = 1 }) => {
  return (dispatch) => {
    dispatch(setFeedStatus(ACTION_STATUS.LOADING));
    return _getCategoryFeeds({ mapId, search, size, page })
      .then((res) => {
        const { rows, count, mapConfig } = res;
        const feedObj = {};
        rows.forEach((feed) => (feedObj[feed.catId] = feed));

        batch(() => {
          dispatch(setCategoryFeeds(feedObj));
          dispatch(setCategoryFeedsCount(count));
          dispatch(setMapFeedConfig(mapConfig));
          dispatch(setFeedStatus(ACTION_STATUS.OK));
        });
      })
      .catch((err) => {
        handleFeedActionError(err, dispatch);
      });
  };
};

export const saveCategoryFeed = ({
  mapId,
  category,
  categoryFeed,
  formMethod,
  callback,
}) => {
  const { catId, name } = category;
  return (dispatch) => {
    dispatch(setFeedStatus(ACTION_STATUS.SAVING));
    return saveUpdateCategoryFeed({ mapId, catId, categoryFeed, formMethod })
      .then(({ feedId }) => {
        const feed = { ...categoryFeed, catId, name };
        batch(() => {
          dispatch(updateCategoryFeed(feed));
          dispatch(setFeedStatus(ACTION_STATUS.OK));
        });
        typeof callback === 'function' && callback();
      })
      .catch((err) => {
        handleFeedActionError(err, dispatch);
      });
  };
};

export const executeLocalistFeed = ({ mapId, catId }) => {
  return (dispatch) => {
    dispatch(setCategoryFeedStatus({ catId, status: ACTION_STATUS.PENDING }));
    return _executeLocalistFeed({ mapId, catId })
      .then((feed) => {
        batch(() => {
          dispatch(updateCategoryFeed({ ...feed }));
        });
      })
      .catch((err) => {
        dispatch(setCategoryFeedStatus({ catId, status: ACTION_STATUS.ERROR }));
        handleFeedActionError(err, dispatch);
      });
  };
};

export const deleteLocalistFeed = ({ mapId, catId }) => {
  return (dispatch) => {
    dispatch(setFeedStatus(ACTION_STATUS.LOADING));
    return _deleteLocalistFeed({ mapId, catId })
      .then((res) => {
        batch(() => {
          dispatch(removeCategoryFeed(catId));
          dispatch(setFeedStatus(ACTION_STATUS.OK));
        });
      })
      .catch((err) => {
        handleFeedActionError(err, dispatch);
      });
  };
};

export const handleFeedActionError = (err, dispatch) => {
  console.error(err);
  if (err.status === 401) {
    dispatch(setFeedStatus(ACTION_STATUS.UNAUTHORIZED));
  } else if (err.status === 404) {
    dispatch(setFeedStatus(ACTION_STATUS.NOTFOUND));
  } else {
    dispatch(setFeedStatus(ACTION_STATUS.ERROR));
  }
};

const saveUpdateMapFeed = ({ accountFeed, method }) => {
  if (method === 'create') {
    return _createMapFeed(accountFeed);
  } else {
    return _updateMapFeed(accountFeed);
  }
};

const saveUpdateCategoryFeed = ({ mapId, catId, categoryFeed, formMethod }) => {
  if (formMethod === 'create') {
    return _createCategoryFeed({ mapId, catId, categoryFeed });
  } else {
    return _updateCategoryFeed({ mapId, catId, categoryFeed });
  }
};

export const setMapFeeds = (data) => {
  return {
    type: FeedActionTypes.SET_MAP_FEEDS,
    data,
  };
};

export const setMapFeedsCount = (data) => {
  return {
    type: FeedActionTypes.SET_MAP_FEEDS_COUNT,
    data,
  };
};

export const setCategoryFeeds = (data) => {
  return {
    type: FeedActionTypes.SET_CATEGORY_FEEDS,
    data,
  };
};

export const setCategoryFeedsCount = (data) => {
  return {
    type: FeedActionTypes.SET_CATEGORY_FEEDS_COUNT,
    data,
  };
};

export const setCategoryFeedStatus = (data) => {
  return {
    type: FeedActionTypes.SET_CATEGORY_FEEDS_STATUS,
    data,
  };
};

export const setMapFeedConfig = (data) => {
  return {
    type: FeedActionTypes.SET_MAP_FEED_CONFIG,
    data,
  };
};

export const updateMapFeedConfig = (data) => {
  return {
    type: FeedActionTypes.UPDATE_MAP_FEED_CONFIG,
    data,
  };
};

export const updateCategoryFeed = (data) => {
  return {
    type: FeedActionTypes.UPDATE_CATEGORY_FEED,
    data,
  };
};

export const removeCategoryFeed = (data) => {
  return {
    type: FeedActionTypes.REMOVE_CATEGORY_FEED,
    data,
  };
};

export const resetCategoryFeeds = () => {
  return (dispatch) => {
    dispatch(setCategoryFeeds({}));
    dispatch(setCategoryFeedsCount(0));
    dispatch(setMapFeedConfig({}));
  };
};

export const setFeedStatus = (data) => {
  return {
    type: FeedActionTypes.SET_FEED_STATUS,
    data,
  };
};
